<template>
  <div class="content-builder">
    <div class="content-settings" v-if="$can('SiteEditPage', 'update')">
      <div class="mb-4 flex justify-between items-center">
        <site-page-import v-if="$can('SiteEditPage', 'import')" @insert-content="page.content.content = $event.content" />
        <button 
          v-if="!isRedirect && page.content.content.length && isEnabled"
          class="ml-auto btn btn-primary" 
          @click.prevent="openPage(false)">
          View Page
        </button>
        <button 
          class="ml-auto btn btn-go" 
          v-if="$can('SiteEditPage', 'update')" 
          @click.prevent="$emit('save-page')">
          {{ page.enabled ? 'Publish' : 'Save' }}
        </button>
      </div>
      <div class="tab-controls justify-center">
        <button
          :class="{ active: $pageBuilder.state.activeTab == 'settings' }"
          @click="$pageBuilder.showTab('settings')"
        >
          Page Settings
        </button>
        <button :class="{ active: $pageBuilder.state.activeTab == 'blocks' }" @click="$pageBuilder.showTab('blocks')">
          Blocks
        </button>
        <button
          :class="{ active: $pageBuilder.state.activeTab == 'blockContent' }"
          @click="$pageBuilder.showTab('blockContent')"
        >
          Block Content
        </button>
      </div>

      <div class="tab" v-show="$pageBuilder.state.activeTab == 'settings'">
        <div class="settings-card settings-block space-y-2 divide-y">
          <rw-select layout="inline" v-model="page.type" :options="pageTypes" label="Page Type" />

          <rw-text layout="inline" v-model="page.title" label="Page Title" placeholder="Enter the page title..." />

          <rw-text layout="inline" v-model="page.slug" label="Slug" placeholder="Enter the slug..." />

          <page-picker layout="inline" label="Parent" v-model="page.parent_id" :siteID="$route.params.siteID" />

          <rw-text
            v-if="page.type !== 'dynamic' && page.type !== 'nestedContent'"
            layout="inline"
            label="Anchor"
            placeholder="Add an optional anchor"
            v-model="page.anchor"
          />

          <light-switch 
            v-model="page.show_in_menu" 
            :disabled="!page.enabled"
            layout="inline">
            <template #label>Show In Menu?</template>
          </light-switch>

          <light-switch
            v-model="page.enabled" 
            layout="inline">
            <template #label>Enabled?</template>
           </light-switch>
        </div>
      </div>

      <div class="tab" v-show="$pageBuilder.state.activeTab == 'blocks'">
        <page-block-picker v-model="page.content" />
      </div>
      <div class="tab" v-show="$pageBuilder.state.activeTab == 'blockContent'">
        <portal-target name="blockSettings"></portal-target>
      </div>
      <div 
        class="flex flex-wrap justify-start mt-4" 
        v-if="showPreviewButtons">
        <p class="flex-grow text-sm	">Preview is only meant for content only. Slugs, page title and other page level changes will affect live pages.</p>
        <button 
          v-if="contentChanged"
          class="btn-sm btn btn-primary mt-2 mr-2" 
          @click.prevent="emitPreviewAction('preview-page')">
            Save Pre
        </button>
        <button 
          class="btn-sm btn btn-go mt-2 mr-2" 
          v-if="isPreviewPage && page.content.content.length" 
          @click.prevent="openPage">
          View Pre
        </button>
        <button 
          class="btn-sm btn btn-stop mt-2" 
          v-if="isPreviewPage && page.content.content.length" 
          @click.prevent="emitPreviewAction('preview-delete')">
            Delete Pre
        </button>
      </div>
    </div>

    <div class="content-editor" @click="$pageBuilder.showTab('settings')">
      <div v-if="page.type != 'redirect'" class="w-full p-4  text-white mb-4 rounded shadow-md" :class="getDraftColor">
        <div class="grid grid-cols-4 gap-4 text-sm">
          <div>
            <strong>Is Page Live On site:</strong>
            {{ modelValue.is_live && this.isEnabled ? 'Yes' : 'No' }}
          </div>
          <div class="capitalize">
            <strong>Currently Viewing Status:</strong>
            {{ modelValue.content.status }} Version
          </div>
          <div class="col-span-2" v-if="modelValue.content.user != null">
            <strong>Last Saved Version Details:</strong>
            {{ getDraftDate }} [{{ page.content.user.email }}]
          </div>
        </div>
      </div>
      <div class="w-full admin-card pr-0">

        <div class="tab-controls mb-4 pt-2">
          <button :class="{ active: editorTab == 'content' }" @click="editorTab = 'content'">Content</button>
          <button :class="{ active: editorTab == 'hero' }" @click="editorTab = 'hero'">Hero</button>
          <button :class="{ active: editorTab == 'serp' }" @click="editorTab = 'serp'">SERP</button>
          <button :class="{ active: editorTab == 'json' }" @click="editorTab = 'json'">JSON</button>
        </div>

        <div id="content" class="overflow-y-auto pr-4">
          <div v-show="editorTab == 'content'">
            <div v-if="isRedirect" class="space-y-4">
              <h3 class="h4 mt-0">Page Type: Redirect</h3>
              <rw-select
                v-model="page.redirect_type"
                :options="{ page: 'Page', entry: 'Entry' }"
                :includeNull="true"
                layout="inline"
                :siteID="$route.params.siteID"
                label="Redirect type"
                class="w-1/3 mt-4"
              />
              <template v-if="page.redirect_type == 'entry'">
                <div v-if="page.redirects_to && !$apollo.queries.entry.loading" class="space-y-0">
                  <p>
                    <strong>Current Selection:</strong>
                    <button @click="page.redirects_to = null" class="ml-4">
                      <fa-icon icon="minus-circle" />
                    </button>
                  </p>
                  <dl class="grid">
                    <dt>Title:</dt>
                    <dd>{{ entry.content.title }} ({{ entry.site_id ? 'Site' : 'Global' }})</dd>
                    <dt>Note:</dt>
                    <dd>
                      <em>{{ entry.admin_note }}</em>
                    </dd>
                    <dt>ID:</dt>
                    <dd>{{ entry.id }}</dd>
                  </dl>
                </div>
                <content-picker
                  class="inline-block"
                  @insert-items="assignRedirect"
                  contentType="entries"
                  allowedTypes="ebook"
                  text="Choose Entry"
                  btnLabel="Insert Entry"
                />
              </template>
              <page-picker
                v-if="page.redirect_type == 'page'"
                layout="inline"
                v-model="page.redirects_to"
                :siteID="$route.params.siteID"
                label="Redirect To"
                class="w-1/3 mt-4"
              />
            </div>

            <template v-if="page.type == 'resource'">
              <h3 class="h4 mt-0">Page Type: Resource Section</h3>
              <rw-select
                layout="inline"
                label="Resource Type"
                v-model="page.section_type"
                :options="resourceTypes"
                class="w-1/3 mt-4"
              />
            </template>

            <template v-if="page.type == 'nestedContent'">
              <h3 class="h4 mt-0">Page Type: Nested Content</h3>
              <div class="space-y-2 settings-block">
                <rw-select
                  layout="inline"
                  label="Section Type"
                  v-model="page.section_type"
                  :options="sectionTypes"
                />

                <template v-if="page.section_type == 'locations'">
                  <content-set-picker
                    :siteID="siteID"
                    v-model="page.content_set_id"
                    layout="inline"
                    :allowedTypes="['location']"
                  />
                  <rw-select
                    layout="inline"
                    label="Variant"
                    v-model="page.variant"
                    :options="{ listing: 'List', 'group-by-state': 'List Grouped by State', map: 'Map' }"
                  />
                </template>

                <template v-if="page.section_type == 'people'">
                  <content-set-picker
                    :siteID="siteID"
                    v-model="page.content_set_id"
                    layout="inline"
                    :allowedTypes="['people']"
                  />
                  <rw-select
                    layout="inline"
                    label="Variant"
                    v-model="page.variant"
                    :options="{
                      'with-vertical-images': 'With Vertical Images',
                      'with-image-and-paragraph': 'With Image and Paragraph',
                    }"
                  />
                </template>

                <template v-if="page.section_type == 'people' || page.section_type == 'locations'">
                  <rw-text v-model="page.intro_title" label="Intro Title" />
                  <rw-textarea v-model="page.intro_text" label="Intro Text" />
                </template>

                <template v-if="page.section_type == 'products'">
                  <content-set-picker
                    :siteID="siteID"
                    v-model="page.content_set_id"
                    layout="inline"
                    :allowedTypes="['product']"
                  />
                  <content-set-item-picker
                    :contentSetID="page.content_set_id"
                    label="Default Item"
                    layout="inline"
                    v-if="page.content_set_id != null"
                    v-model="page.redirects_to"
                  />
                </template>
              </div>
            </template>

            <template v-if="page.type == 'dynamic'">
              <h3 class="h4 mt-0">Page Type: Dynamic Section</h3>
              <rw-select
                layout="inline"
                label="Section Type"
                v-model="page.section_type"
                :options="sectionTypes"
                class="w-1/3 my-4"
              />

              <div v-show="page.section_type == 'contentSet'">
                <p>Be sure to add a slug for the default page.</p>
                <rw-text layout="inline" label="Default page" v-model="page.anchor" class="w-1/3 mb-4" />
              </div>
            </template>

            <draggable
              v-show="page.type == 'default' || (page.type == 'dynamic' && page.section_type == 'contentSet')"
              class="section-list drop-zone"
              :list="page.content.content"
              :group="{ name: 'sections', put: ['sectionBlocks', 'sectionTemplates'] }"
              handle=".handle"
              item-key="_id"
            >
              <template #item="{ element, index }">
                <draggable-item
                  v-model="page.content.content[index]"
                  handleClass="handle"
                  :ref="'childBlock:' + index"
                  @update:modelValue="updateBlock($event, index)"
                  @remove-child="removeChild(index)"
                  @insert-preset="updateSection($event, index)"
                />
              </template>
            </draggable>
            
          </div>

          <div v-show="editorTab == 'hero'">
            <rw-hero :editable="$can('SiteEditPage', 'update')" context="standalone" v-model="hero" :site-id="siteID" />
          </div>

          <div v-if="editorTab == 'serp'" class="admin-card">
            <div class="admin-card--header">
              <h3 class="h4 m-0">SERP</h3>
            </div>
            <seo-form :editable="$can('SiteEditPage', 'update')" :entry="page" v-model="page.content.seo" />
          </div>

          <div v-if="editorTab == 'json'" class="admin-card">
            <div>
              <pre class="whitespace-pre-wrap">{{ page.content.content }}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlockDefaults from '@/components/mixins/blockDefaults'
import { ENTRY } from '@/graphql/entries'
import { pageStatusTypes } from '@/settings/enums'
import moment from 'moment'
import 'moment-timezone';
import { reactive } from 'vue'

export default {
  name: 'PageBuilder',
  props: ['modelValue', 'siteKey', 'siteState'],
  mixins: [BlockDefaults],
  data() {
    return {
      isPreviewDelete: false,
      siteID: this.$route.params.siteID,
      activeTab: this.$pageBuilder.state.activeTab,
      pageTypes: {
        default: 'Default',
        redirect: 'Redirect',
        resource: 'Resource',
        dynamic: 'Dynamic',
        nestedContent: 'Nested Content Set',
        // menuItem: 'Menu Item',
        // fallback: 'Fall Back (catches child pages without content)',
      },
      resourceTypes: {
        all: 'All',
        ebook: 'eBooks',
        quickRead: 'QuickReads',
        blog: 'Blog',
        magazine: 'Magazine',
      },
      entry: {},
      editorTab: 'content',
      enabled: false,
      hero: this.modelValue.section_type == 'products' ? this.modelValue.content_set.hero_override : this.modelValue.content.hero,
      pageStatusTypes: pageStatusTypes,
      contentChanged: false,
    }
  },
  computed: {
    page: {
      get() {
        return reactive(this.modelValue);
      },
      set(newVal) {
        this.$emit('update:modelValue', newVal)
      }
    },
    sectionTypes() {
      if (this.modelValue.type == 'dynamic') {
        return {
          contentSet: 'Content Set',
        }
      }
      if (this.modelValue.type == 'nestedContent') {
        return {
          products: 'Products',
          locations: 'Locations',
          people: 'People',
        }
      }
    },
    isRedirect() {
      return this.modelValue.type == 'redirect';
    },
    isPreviewPage() {
      return (this.modelValue.content.status == pageStatusTypes.draft);
    },
    isEnabled() {
      return this.modelValue.enabled;
    },
    getDraftDate() {
      let draft = moment.tz(this.modelValue.content.updated_at, "America/New_York");

      return draft.format('MMM D YYYY h:mm a z');
    },

    // Returns the bg color based if the page is on live and viewing a draft.
    getDraftColor() {
      if (this.modelValue.is_live && this.isPreviewPage) {
        return 'bg-red-900';
      }

      return 'bg-gray-600';
    },

    // There are a lot of coniditions to meet before the preview buttons are enabled.
    showPreviewButtons() {
      if (
        this.$can('SiteEditPage', 'update') 
        && this.siteState == 'live' 
        && !this.isRedirect 
        && this.isEnabled 
        && (this.modelValue.type == 'default')) {
          return true;
      }

      return false;
    },
  },
  watch: {
    'page.content': {
      deep: true,
      handler() {
        // A preview delete was fired. Content was reverted.
        if (this.isPreviewDelete) {
          this.contentChanged = false;
          this.isPreviewDelete = false;
        } else {
          this.contentChanged = true;
        }
      }
    },
    hero: {
      deep: true,
      handler(newVal) {
        if (this.page.section_type == 'products') {
          this.page.content_set.hero_override = newVal;
        } else {
          this.page.content.hero = newVal
        }
      },
    },
    'page.enabled': {
      handler(newVal) {
        if (!newVal) {
          this.page.show_in_menu = false;
        }
      }
    },
    'page.type': {
      handler(newVal) {
        if (newVal == 'default') {
          this.page.section_type = null;
        }

        if (newVal !== 'redirect') {
          this.page.redirects_to = null
        }
        if (newVal == 'redirect' || 'nestedContent') {
          this.page.anchor = null
        }
      },
    },
  },
  mounted() {
    let content = document.getElementById('content')
    let offset = this.$pageBuilder.offset(content)
    content.setAttribute('style', `max-height: calc(100vh - ${offset.top + 40}px)`)
  },
  apollo: {
    entry: {
      query: ENTRY,
      variables() {
        return {
          entryID: this.page.redirects_to,
        }
      },
      skip() {
        return this.page.redirect_type !== 'entry' || !this.page.redirects_to
      },
    },
  },
  methods: {
    assignRedirect($event) {
      let id = $event.data[0] ? $event.data[0].id : null
      this.page.redirects_to = id;
    },
    updateBlock($event, idx) {
      if (!this.$can('SiteEditPage', 'update')) {
        return;
      }
      this.page.content['content'][idx] = $event;
    },
    updateSection($event, idx) {
      if (!this.$can('SiteEditPage', 'update')) {
        return;
      }

      let newBlock = Object.assign({}, $event)
      this.page.content['content'][idx] = newBlock;
      
      this.$nextTick(function () {
        let ref = `childBlock:${idx}`;
        this.$refs[ref][0].editBlockContent()
      })
    },
    removeChild(idx) {
      if (!this.$can('SiteEditPage', 'update')) {
        return;
      }

      this.page.content.content.splice(idx, 1)
    },
    openPage(viewDraft = true) {
      const env = process.env;
      let previewUrl = '';
      let query = `/${this.page.uri}`;
      query += viewDraft ? `?status=${this.pageStatusTypes.draft}` : '';

      previewUrl = 'https://'+this.siteKey+env.VUE_APP_PREVIEW_URL+query;

      window.open(previewUrl, '_blank');
    },
    // This will help the save pre button show at the right time.
    emitPreviewAction(action) {
      this.isPreviewDelete = action == 'preview-delete' ? true : false;
      this.$emit(action);
    }
  },
}
</script>