<template>
  <div class="form-control grid gap-4 grid-cols-4">
    <label class="self-center">{{ label }}</label>
    <div class="">
    <rw-text
      :disabled="!editable"
      v-model="block.font_family"
      label="Font Family (Include any quotations)"
      placeholder="Font Family"
    />
    </div>
    <div class="">
    <rw-text
      :disabled="!editable"
      v-model="block.font_size"
      label="Font Size"
      placeholder="Font Size"
    />
    </div>
    <div class="">
    <rw-text
      :disabled="!editable"
      v-model="block.font_weight"
      label="Font Weights"
      placeholder="Font Weights"
    />
    </div>
  </div>
</template>

<script>
import { reactive } from 'vue';
import defaultsDeep from 'lodash/defaultsDeep'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'FontLine',
  props: {
    editable: {
      type: Boolean, 
      default: false
    },
    label: {
      type: String,
      required: false
    },
    modelValue: {
      type: Object
    }
  },
  data() {
    return {
      overrideDefaults: {
        font_family: '',
        font_size: '',
        font_weight: '',
      },
    }
  },
  computed: {
    block: {
      get() {
        return defaultsDeep(reactive(this.modelValue), cloneDeep(this.overrideDefaults));
      },
      set(newVal) {
        this.$emit('update:modelValue', newVal);
      }
    }
  },
  
  methods: {
    
  },
}
</script>

<style scoped>
  label {
    font-size: 16px;
    font-weight: 700;
  }
</style>>