<template>
  <div>
    <div
      v-if="context == 'preview' "
      :class="[ block.blockLayout == 'title-offset-left' ? 'flex' : null ]"
    >
      <div
        v-if="hasTitles"
        class="mb-12"
        :class="[ block.blockLayout == 'title-offset-left' ? 'w-1/2' : 'text-center' ]"
      >
        <p v-show="block.preTitle" v-html="block.preTitle"></p>
        <h2 v-show="block.title" v-html="block.title"></h2>
        <p v-show="block.subTitle" v-html="block.subTitle"></p>
      </div>

      <p v-if="!block.children.length" class="mx-2 my-0">Drag a block here to get started</p>
      <draggable
        class="container -mx-2"
        :class="{ 'py-8 px-3 border border-gray-600 border-dashed': !block.children.length, 'w-1/2' : block.blockLayout == 'title-offset-left' }"
        :list="block.children"
        :group="{ name: 'container', put: ['container'] }"
        handle=".container-handle"
        item-key="_id"
      >
        <template #item="{ element, index }">
          <draggable-item
            handleClass="container-handle"
            v-model="block.children[index]"
            :ref="'childBlock:' + index"
            @remove-child="removeChild(index)"
            @insert-preset="insertPreset($event, index)"
          />
      </template>
      </draggable>
    </div>

    <div v-if="context == 'settings'" class="space-y-2">
      <rw-select
        :options="{ 'people': 'People', 'feature': 'Feature' }"
        v-model="block.variantType"
        :includeNull="true"
        label="Variant Type"
        layout="inline"
      />
      <rw-select
        v-if="block.variantType == 'people'"
        :options="{ 'with-vertical-images': 'With Vertical Images', 'with-image-and-paragraph' : 'With Image and Paragraph' }"
        v-model="block.blockVariant"
        :includeNull="true"
        label="Variant"
        layout="inline"
      />
      <rw-select
        v-if="block.variantType == 'feature'"
        :options="{ 'offset-grid': 'Offset Grid', 'centered-grid': 'Centered Grid', 'simple-columns' : 'Simple Columns' }"
        v-model="block.blockVariant"
        :includeNull="true"
        label="Variant"
        layout="inline"
      />
      <rw-select
        :options="availableLayouts"
        v-model="block.blockLayout"
        :includeNull="true"
        label="Layout"
        layout="inline"
      />
      <!-- <rw-select :options="availableStyles" v-model="block.blockStyle" :includeNull="true" label="Style" /> -->
      <rw-select
        :options="availableBgStyles"
        v-model="block.bgStyle"
        :includeNull="true"
        label="BG Style"
        layout="inline"
      />

      <rw-select
        v-if="block.bgStyle == 'bg-image'"
        :options="{ 'lightbg': 'Light', 'darkbg': 'Dark' }"
        v-model="block.bgShade"
        label="BG Shade"
        layout="inline"
        class="mt-3"
      />

      <rw-image
        v-if="block.bgStyle == 'bg-image'"
        v-model="block.bgImage"
        context="settings"
        label="Background Image"
        :includeOverlay="true"
        :includeAlt="false"
        :includeSrc="false"
        :includeStyle="false"
        :includeCrop="true"
      />

      <rw-select
        v-if="block.bgStyle != 'bg-image'"
        class="mb-4"
        :options="titleColors"
        v-model="block.titleColor"
        :includeNull="true"
        label="Title &amp; Text Color"
        layout="inline"
      />

      <rw-text v-model="block.cssClass" label="CSS Class" placeholder="Enter the tailwind classes..." />

      <rw-text v-model="block.preTitle" placeholder="Enter the PreTitle" label="PreTitle" />
      <rw-text v-model="block.title" placeholder="Enter the title" label="Title" />
      <rw-text v-model="block.subTitle" placeholder="Enter the SubTitle" label="SubTitle" />
    </div>
  </div>
</template>

<script>
import { titleColors } from '@/settings/enums'
import EventBus from '@/bootstrap/EventBus'
import { provide } from 'vue'

export default {
  name: 'Section',
  mixins: [BlockMixin, InsertPreset],
  provide() {
    return {
      getSection: this.block
    };
  },
  data() {
    return {
      titleColors: titleColors,
      getSection: {blockLayout: null}
    }
  },
  
  computed: {
    hasTitles() {
      return this.block.preTitle || this.block.title || this.block.subTitle
    },
    availableBgStyles() {
      return {
        'bg-subtle': 'Background - Subtle',
        'bg-primary': 'Background - Brand Color',
        'bg-secondary': 'Background - Secondary Accent',
        'bg-third': 'Background - Third Accent',
        'bg-fourth': 'Background - Fourth Accent',
        'bg-image': 'Background - Image',
      }
    },
    availableLayouts() {
      return {
        'title-offset-left': 'Title Offset - Left',
      }
    },
  },
  created() {
    EventBus.on('asset-chosen', this.updateImage)
  },
  beforeUnmounted() {
    EventBus.off('asset-chosen')
  },
  methods: {
    updateImage(data) {
      console.log('Section.vue - updateImage', data, this.$.uid, data.uid)
      if (this.$.uid === data.uid) {
        this.block['bgImage'] = {
          src: data.asset.secure_url,
          cloudinary: data.asset,
          cloudinaryId: data.asset.public_id,
        };
      }
    },
    removeImage() {
      this.block['bgImage'] = {
        src: null,
        cloudinary: {},
        cloudinaryId: null,
      }
    },
  },
}
</script>