<template>
  <div>
    <div>
      <rw-text v-model="fontImportMods" :disabled="!editable" lines="5" label="Google Font Imports (use links vs import)" />
      <a href="https://fonts.google.com/" class="btn btn-primary" target="_blank">Google Fonts</a>
    </div>

    <div class="mt-2">
      <hr class="mt-0" />
      <h4>Font Headings</h4>
      <div class="my-4 py-2">
        <font-line label="Body" :editable="editable" v-model="fontOverrides.body" ></font-line>
      </div>
      <div class="my-4 py-2">
        <font-line label="Heading 1" :editable="editable" v-model="fontOverrides.h1"></font-line>
      </div>
      <div class="my-4 py-2">
        <font-line label="Heading 2" :editable="editable" v-model="fontOverrides.h2"></font-line>
      </div>
      <div class="my-4 py-2">
        <font-line label="Heading 3" :editable="editable" v-model="fontOverrides.h3"></font-line>
      </div>
      <div class="my-4 py-2">
        <font-line label="Heading 4" :editable="editable" v-model="fontOverrides.h4"></font-line>
      </div>
      <div class="my-4 py-2">
        <font-line label="Heading 5" :editable="editable" v-model="fontOverrides.h5"></font-line>
      </div>
      <div class="my-4 py-2">
        <font-line label="Heading 6" :editable="editable" v-model="fontOverrides.h6"></font-line>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FontStyles',
  props: ['siteSettings', 'editable'],
  data() {
    return {
      fontImportMods: '', 
      fontOverrides: {
        body: {
          font_family: '',
          font_size: '',
          font_weight: '',
        },
        h1: {
          font_family: '',
          font_size: '',
          font_weight: '',
        },
        h2: {
          font_family: '',
          font_size: '',
          font_weight: '',
        },
        h3: {
          font_family: '',
          font_size: '',
          font_weight: '',
        },
        h4: {
          font_family: '',
          font_size: '',
          font_weight: '',
        },
        h5: {
          font_family: '',
          font_size: '',
          font_weight: '',
        },
        h6: {
          font_family: '',
          font_size: '',
          font_weight: '',
        },
      },
    }
  },
  mounted() {
    this.fontImportMods = this.siteSettings.font_import;

    this.fontOverrides = {...this.fontOverrides, ...this.siteSettings.font_overrides};
  },
  watch: {
    fontImportMods: {
      deep: true,
      handler(newVal) {
        this.$emit('updateImport', newVal)
      },
    },
    fontOverrides: {
      deep: true,
      handler(newVal) {
        this.$emit('updateOverrides', newVal)
      },
    },
  },
  
}
</script>

<style>
</style>